@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: var(--image-gradient), url(./Assets/liquid-cheese.svg);
  animation:  gradient 60s ease infinite;
  background-size: 400% 400%;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

}

@media (max-width: 767px) {
  body{
    background-size: 2055%, 2000% !important;
  }
}

@media (max-width: 1000px) {
  body{
    background-size: 2055%, 2000% !important;
  }
}

.body::-webkit-scrollbar {
  display: none;
}